<template>
  <v-card class="border-secondary rounded-xl pa-sm-8" elevation="0">
    <v-progress-linear :active="loading" indeterminate color="primary" />

    <v-card-text v-if="!success">
      <p class="text-center">
        RESET PASSWORD
      </p>
      <v-form>
        <v-text-field
          :id="seleniumIds.SELENIUM_TEST_RESET_PASSWORD_PASSWORD_FIELD"
          v-model="password"
          label="Password"
          outlined
          required
          :append-icon="iconOfPasswordShowToggler"
          :type="passwordFieldType"
          :error-messages="errors.password"
          @click:append="togglePasswordShow()"
          @input="errors.password = []"
        />
        <v-text-field
          :id="seleniumIds.SELENIUM_TEST_RESET_PASSWORD_PASSWORD_CONFIRM_FIELD"
          v-model="passwordConfirm"
          label="Confirm Password"
          outlined
          required
          :append-icon="iconOfPasswordShowToggler"
          :type="passwordFieldType"
          :error-messages="errors.password"
          @click:append="togglePasswordShow()"
          @input="errors.password = []"
        />
        <c-btn
          color="primary"
          depressed
          large
          block
          :label="'Reset password'"
          @click="reset()"
        />
      </v-form>
    </v-card-text>

    <v-card-text v-if="success">
      <p>Password was successfully changed.</p>
      <c-btn
        label="Log in"
        color="primary"
        depressed
        @click="redirectToLoginPage()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import { personalRepository } from '@/services/repository-factory.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'ResetPassword',
    components: {
      CBtn
    },
    data() {
      return {
        loading: false,
        email: this.$route.query.email || '',
        token: this.$route.query.token || '',
        password: '',
        passwordConfirm: '',
        showPass: false,
        autoRedirect: null,
        errors: {},
        success: false,
        seleniumIds
      }
    },
    computed: {
      iconOfPasswordShowToggler() {
        return this.showPass ? 'mdi-eye' : 'mdi-eye-off'
      },
      passwordFieldType() {
        return this.showPass ? 'text' : 'password'
      }
    },
    methods: {
      togglePasswordShow() {
        this.showPass = !this.showPass
      },
      async redirectToLoginPage() {
        await this.$auth.logout()
        await this.$router.push({ name: routeNames.LOGIN })
        clearTimeout(this.autoRedirect)
      },
      autoRedirectToLoginPage() {
        this.autoRedirect = setTimeout(() => {
          this.$router.push({ name: routeNames.LOGIN })
        }, 2500)
      },
      reset() {
        this.loading = true

        const {
          email, password, passwordConfirm, token
        } = this
        personalRepository
          .setNewPassword({
            email,
            password,
            passwordConfirm,
            token
          })
          .then(() => {
            this.loading = false
            this.success = true
            this.autoRedirectToLoginPage()
          })
          .catch((e) => {
            this.loading = false
            this.errors = e.response.data.errors
          })
      }
    }
  }
</script>
